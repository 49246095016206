<!--
 * @Description: 反馈
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-12 08:05:50
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar';
export default {
  components: {
    SideBar,
  },

  data() {
    return {
      groups: [
        {
          label: '加盟管理',
          list: [
            {
              name: '加盟申请',
              path: '/mall/feedback/feedbackList',
            },
          ],
        },
      ],
    };
  },
};
</script>
